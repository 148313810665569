import React, { useState, useEffect, useRef } from 'react';
import { 
    Box, Container, Paper, Typography, Button, TextField, 
    CircularProgress, LinearProgress, Toolbar, MenuItem, Select,
    List, ListItem, ListItemText, Divider, IconButton, Drawer, Snackbar, Alert, Tooltip
} from '@mui/material';
import { getToken, getUserId } from '../../auth/auth.js';
import { BASE_URL } from '../../Constants';
import YouTube from 'react-youtube';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useTheme } from '@mui/material/styles';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

function ChatHistoryNavbar({ chatHistory, onThreadSelect }) {
    // Filter out threads with 0 messages
    const nonEmptyThreads = chatHistory.filter(thread => thread.messageCount > 0);

    return (
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            {nonEmptyThreads.map((thread) => (
                <React.Fragment key={thread.threadId}>
                    <ListItem button onClick={() => onThreadSelect(thread.threadId)}>
                        <ListItemText 
                            primary={`Chat ${new Date(thread.createdAt).toLocaleDateString()}`}
                            secondary={`${thread.lastMessage} (${thread.messageCount} messages)`}
                        />
                    </ListItem>
                    <Divider component="li" />
                </React.Fragment>
            ))}
        </List>
    );
}

function LMSHome() {
    const [videos, setVideos] = useState([]);
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const [messages, setMessages] = useState([]);
    const [lmsThreadId, setLMSThreadId] = useState(null);
    const [inputMessage, setInputMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const messagesEndRef = useRef(null);
    const [isLoadingMessages, setIsLoadingMessages] = useState(true);
    const [player, setPlayer] = useState(null);
    const lastSentDurationRef = useRef({});
    const [chatHistory, setChatHistory] = useState([]);
    const [isChatHistoryVisible, setIsChatHistoryVisible] = useState(false);
    const [isLoadingVideos, setIsLoadingVideos] = useState(false); // Set initial value to false
    const [selectedCourseId, setSelectedCourseId] = useState(null);
    const [videoError, setVideoError] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(true); // Default to open
    const [courses, setCourses] = useState([]);
    const drawerWidth = 240;
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');
    const [pendingSeek, setPendingSeek] = useState(null);
    const [courseThreadIds, setCourseThreadIds] = useState({});
    const [newestThreadId, setNewestThreadId] = useState(null);

    const CLEAR_COMMANDS = ['/clear', 'clear my convo', 'clear chat', 'clear my chat', 'clear', 'clear convo', 'clea'];

    const token = getToken();
    const userId = getUserId();

    const theme = useTheme();

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const fetchVideos = async (course_name) => {
        setIsLoadingVideos(true);
        try {
            const response = await fetch(`${BASE_URL}/api/getlmsvideos?course_name=${course_name}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            console.log('data',data);
            
            setVideos(data);
            setCurrentVideoIndex(0); // Reset video index when changing course
            setIsLoadingVideos(false);
        } catch (error) {
            console.error('Error fetching videos:', error);
            setVideoError(error.message);
            setIsLoadingVideos(false);
        }
    };

    const handleCourseSelect = async (course_name) => {
        setSelectedCourseId(course_name);
        setIsLoadingVideos(true);
        try {
            const response = await fetch(`${BASE_URL}/api/getlmsvideos?course_name=${course_name}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            
            setVideos(data);
            setCurrentVideoIndex(0);

            await loadThreadAndMessages(course_name);

            if (data.length === 0) {
                setSnackbarMessage('No videos available in this course');
                setSnackbarSeverity('info');
                setOpenSnackbar(true);
            }
        } catch (error) {
            console.error('Error:', error);
            setVideoError(error.message);
            setSnackbarMessage('Error loading course content');
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
        } finally {
            setIsLoadingVideos(false);
        }
    };

    const loadThreadAndMessages = async (course_name) => {
        setIsLoadingMessages(true);
        try {
            let threadId = courseThreadIds[course_name];
            if (!threadId) {
                threadId = await createThread(course_name);
                if (!threadId) {
                    throw new Error('Failed to create thread');
                }
                setCourseThreadIds(prev => ({
                    ...prev,
                    [course_name]: threadId
                }));
                setNewestThreadId(threadId);
            }
            setLMSThreadId(threadId);
            await fetchMessages(threadId);
        } catch (error) {
            console.error('Error loading thread and messages:', error);
        } finally {
            setIsLoadingMessages(false);
        }
    };

    useEffect(() => {
        if (selectedCourseId) {
            fetchChatHistory();
        }
    }, [userId, selectedCourseId]);

    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const response = await fetch(`${BASE_URL}/api/courses`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch courses');
                }
                const data = await response.json();
                setCourses(data);
                
                // If there are courses and no course is currently selected, select the first one
                if (data.length > 0 && !selectedCourseId) {
                    handleCourseSelect(data[0].course_name);
                }
            } catch (error) {
                console.error('Error fetching courses:', error);
            }
        };

        fetchCourses();
    }, [token, selectedCourseId]);

    const navigateVideos = (direction) => {
        const newIndex = currentVideoIndex + direction;
        if (newIndex >= 0 && newIndex < videos.length) {
            setCurrentVideoIndex(newIndex);
        }
    };

    useEffect(() => { 
        if (player) {
          const interval = setInterval(handleTimeUpdate, 30000);
          return () => clearInterval(interval);
        }
    }, [player]);

    const onReady = (event) => {
        setPlayer(event.target);
    };

    const handleTimeUpdate = () => {
        if (player && player.getCurrentTime) {
            const currentTime = Math.floor(player.getCurrentTime());
            const videoId = videos[currentVideoIndex].id;
            
            if (currentTime > (lastSentDurationRef.current[videoId] || 0)) {
                updateVideoProgress(currentTime, videoId);
            }
        }
    };
      
    const updateVideoProgress = async (currentTime, videoId) => {
        console.log('Updating video progress:', { currentTime, videoId });
        try {
            const response = await fetch(`${BASE_URL}/api/videoprogress`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    userId,
                    videoId: videoId,
                    watchedDuration: currentTime
                }),
            });
          
          const data = await response.json();
          
            if (data.success) {
                lastSentDurationRef.current[videoId] = currentTime;
                console.log(data.message);
                console.log('Progress update successful:', data.message);
            } else {
                console.log('Progress update not needed:', data.message);
            }
        } catch (error) {
            console.error('Error updating video progress:', error);
        }
    };

    function getYouTubeId(url) {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        const match = url.match(regExp);
        return (match && match[2].length === 11) ? match[2] : null;
    }

    const createThread = async (course_name) => {
        try {
            const response = await fetch(`${BASE_URL}/api/createLMSthread`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ course_name })
            });
            
            if (!response.ok) {
                throw new Error('Failed to create thread');
            }
            
            const data = await response.json();
            return data.threadId;
        } catch (error) {
            console.error('Error creating thread:', error);
            return null;
        }
    };

    const fetchMessages = async (threadId) => {
        const idToUse = threadId || lmsThreadId;
        if (!idToUse) return;
      
        try {
          const response = await fetch(`${BASE_URL}/api/getLMSmessages/${idToUse}`, {
            headers: {
              'Authorization': `Bearer ${token}`
            },
          });
      
          if (!response.ok) {
            throw new Error('Failed to fetch messages');
          }
      
          const data = await response.json();
          setMessages(data.messages);
        } catch (error) {
          console.error('Error fetching messages:', error);
        }
    };

    const fetchChatHistory = async () => {
        if (!selectedCourseId) return; // Don't fetch if no course is selected
        
        try {
            const response = await fetch(`${BASE_URL}/api/getLMSChatHistory?course_name=${selectedCourseId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch chat history');
            }

            const data = await response.json();
            setChatHistory(data.chatHistory);
        } catch (error) {
            console.error('Error fetching chat history:', error);
        }
    };

    const sendMessage = async () => {
        if (!inputMessage.trim() || !lmsThreadId) return;

        const normalizedInput = inputMessage.trim().toLowerCase();
        if (CLEAR_COMMANDS.includes(normalizedInput)) {
            await startNewChat();
            setInputMessage('');
            return;
        }
    
        const newMessage = { role: 'user', content: inputMessage };
        setMessages(prevMessages => [...prevMessages, newMessage]);
        setInputMessage('');
        setIsLoading(true);
    
        try {
            await fetch(`${BASE_URL}/api/sendLMSchatmessage`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    threadId: lmsThreadId,
                    message: inputMessage,
                    course_name: selectedCourseId
                }),
            });
    
            await fetchMessages(lmsThreadId);
        } catch (error) {
            console.error('Error sending message:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const startNewChat = async () => {
        if (!selectedCourseId) return;
        
        setIsLoadingMessages(true);
        localStorage.removeItem(`lmsThreadId_${userId}`);
        setMessages([]);
        const newThreadId = await createThread(selectedCourseId);
        if (newThreadId) {
            setLMSThreadId(newThreadId);
            await fetchMessages(newThreadId);
        }
        setIsLoadingMessages(false);
    };

    const handleThreadSelect = async (threadId) => {
        setLMSThreadId(threadId);
        setIsLoadingMessages(true);
        try {
            await fetchMessages(threadId);
        } catch (error) {
            console.error('Error loading messages:', error);
        } finally {
            setIsLoadingMessages(false);
        }
    };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const handleVideoSelect = (event) => {
        const newIndex = parseInt(event.target.value, 10);
        setCurrentVideoIndex(newIndex);
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const onStateChange = (event) => {
        if (event.data === 1 && pendingSeek !== null) {
            setTimeout(() => {
                event.target.seekTo(pendingSeek);
                event.target.playVideo();
                setPendingSeek(null);
            }, 100);
        } else if (event.data === -1 && pendingSeek !== null) {
            event.target.playVideo();
        }
    };

    const renderVideoSection = () => {
        if (!selectedCourseId) {
            return <Typography>Please select a course to view videos.</Typography>;
        }

        if (isLoadingVideos) {
            return <CircularProgress />;
        }

        if (videoError) {
            return <Typography color="error">Error loading videos: {videoError}</Typography>;
        }

        if (videos.length === 0) {
            return <Typography>No videos available for this course.</Typography>;
        }

        return (
            <Paper sx={{ width: { xs: '100%', md: '65%' }, p: 2 }}>
                <Typography variant="h5">{videos[currentVideoIndex].title}</Typography>
                <Typography variant="caption">Video {currentVideoIndex + 1} of {videos.length}</Typography>
                <Box sx={{ position: 'relative', pb: '56.25%', height: 0 }}>
                    <YouTube
                        videoId={getYouTubeId(videos[currentVideoIndex].url)}
                        opts={{
                            width: '100%',
                            height: '100%',
                            playerVars: {
                                modestbranding: 1,
                                rel: 0,
                                enablejsapi: 1
                            },
                        }}
                        onReady={onReady}
                        onStateChange={onStateChange}
                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                    />
                </Box>
                <Typography 
                    variant="body2" 
                    sx={{ 
                        mt: 1, 
                        p: 1, 
                        backgroundColor: 'grey.200', 
                        borderRadius: 1, 
                        fontStyle: 'italic' 
                    }}
                >
                    Note: Your progress will not be saved if you watch directly on YouTube.
                </Typography>
                <Select
                    value={currentVideoIndex}
                    onChange={handleVideoSelect}
                    fullWidth
                    sx={{ mt: 2 }}
                >
                    {videos.map((video, index) => (
                        <MenuItem key={video.id} value={index}>
                            {video.title}
                        </MenuItem>
                    ))}
                </Select>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                    <Button variant="contained" onClick={() => navigateVideos(-1)} disabled={currentVideoIndex === 0}>
                        Previous
                    </Button>
                    <Button variant="contained" onClick={() => navigateVideos(1)} disabled={currentVideoIndex === videos.length - 1}>
                        Next
                    </Button>
                </Box>
            </Paper>
        );
    };

    // Helper function to normalize strings for comparison
    const normalizeString = (str) => {
        return str
            .toLowerCase()
            // Remove special characters and extra spaces
            .replace(/[^\w\s]/g, '')
            .replace(/\s+/g, ' ')
            .trim();
    };

    // Helper function to find best matching video
    const findBestMatchingVideo = (searchTitle, videosList) => {
        const normalizedSearch = normalizeString(searchTitle);
        
        // First try: Direct match after normalization
        const directMatch = videosList.findIndex(video => 
            normalizeString(video.title) === normalizedSearch
        );
        
        if (directMatch !== -1) return directMatch;

        // Second try: Contains all words (in any order)
        const searchWords = normalizedSearch.split(' ');
        const containsMatch = videosList.findIndex(video => {
            const normalizedVideoTitle = normalizeString(video.title);
            return searchWords.every(word => 
                normalizedVideoTitle.includes(word)
            );
        });

        if (containsMatch !== -1) return containsMatch;

        // Third try: Most words match
        let bestMatchIndex = -1;
        let maxMatchingWords = 0;

        videosList.forEach((video, index) => {
            const videoWords = normalizeString(video.title).split(' ');
            let matchingWords = 0;
            
            searchWords.forEach(searchWord => {
                if (videoWords.some(videoWord => videoWord.includes(searchWord) || searchWord.includes(videoWord))) {
                    matchingWords++;
                }
            });

            if (matchingWords > maxMatchingWords) {
                maxMatchingWords = matchingWords;
                bestMatchIndex = index;
            }
        });

        // Return best match if it matches at least 50% of words
        if (maxMatchingWords >= searchWords.length * 0.5) {
            return bestMatchIndex;
        }

        return -1;
    };

    const handleTimestampClick = (timestamp, videoTitle) => {
        const videoIndex = findBestMatchingVideo(videoTitle, videos);
        
        if (videoIndex !== -1) {
            const [minutes, seconds] = timestamp.split(':').map(Number);
            const timeInSeconds = minutes * 60 + seconds;
            
            if (videoIndex === currentVideoIndex) {
                if (player && player.seekTo) {
                    player.seekTo(timeInSeconds);
                    player.playVideo();
                }
            } else {
                setPendingSeek(timeInSeconds);
                setCurrentVideoIndex(videoIndex);
            }
        } else {
            setSnackbarMessage('Could not find matching video');
            setSnackbarSeverity('warning');
            setOpenSnackbar(true);
        }
    };

    // Add this helper function before renderMessage
    const processText = (text) => {
        // Split text by spaces while preserving spaces and bold formatting
        return text.split(/(\s+|\*\*.*?\*\*)/).map((part, index) => {
            // Return spaces as-is
            if (/^\s+$/.test(part)) {
                return part;
            }
            // Handle bold text (wrapped in **)
            if (part.startsWith('**') && part.endsWith('**')) {
                return (
                    <span 
                        key={index} 
                        style={{ fontWeight: 'bold' }}
                    >
                        {part.slice(2, -2)}
                    </span>
                );
            }
            // Return non-space parts as text nodes
            return <span key={index}>{part}</span>;
        });
    };

    const renderMessage = (msg, index) => {
        const formatContent = (content) => {
            content = content.replace(/\[[\d:†\w.-]+\]/g, '');
            content = content.replace(/【[^】]*】/g, '');

            return content.split('\n').map((line, i) => {
                const parts = [];
                let lastIndex = 0;

                // Updated regex to handle HH:MM:SS.mmm, HH:MM:SS, and MM:SS formats
                const timestampRegex = /\((\d{2}:?\d{2}:\d{2}(?:\.\d{1,3})?|\d{1,2}:\d{2})\s*-\s*/g;
                let match;

                while ((match = timestampRegex.exec(line)) !== null) {
                    // Process text before timestamp
                    if (match.index > lastIndex) {
                        parts.push(...processText(line.slice(lastIndex, match.index)));
                    }

                    // Convert timestamp to MM:SS format
                    let timestamp = match[1];
                    // Remove milliseconds if present
                    timestamp = timestamp.split('.')[0];
                    
                    if (timestamp.length > 5) {  // It's in HH:MM:SS format
                        const [hours, minutes, seconds] = timestamp.split(':');
                        const totalMinutes = parseInt(hours) * 60 + parseInt(minutes);
                        timestamp = `${totalMinutes}:${seconds}`;
                    }

                    // Rest of the code for finding video title and creating clickable element
                    const startIndex = match.index;
                    let openParens = 1;
                    let endIndex = startIndex + match[0].length;
                    
                    for (let j = endIndex; j < line.length; j++) {
                        if (line[j] === '(') openParens++;
                        if (line[j] === ')') {
                            openParens--;
                            if (openParens === 0) {
                                endIndex = j + 1;
                                break;
                            }
                        }
                    }

                    const fullMatch = line.slice(startIndex, endIndex);
                    const videoTitle = fullMatch
                        .slice(match[0].length, -1)
                        .trim();

                    parts.push(
                        <Tooltip 
                            title={videoTitle}
                            enterDelay={500}
                            arrow
                        >
                            <Box
                                component="span" 
                                onClick={() => handleTimestampClick(timestamp, videoTitle)}
                                sx={{ 
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    fontSize: '0.9rem',
                                    mx: 0.5,
                                    gap: '4px',
                                    color: 'primary.main',
                                    position: 'relative',
                                    padding: '3px 8px',
                                    borderRadius: '4px',
                                    transition: 'all 0.2s ease',
                                    backgroundColor: 'rgba(25, 118, 210, 0.08)',
                                    '&:hover': {
                                        backgroundColor: 'rgba(25, 118, 210, 0.15)',
                                        color: 'primary.dark',
                                        '& .play-icon': {
                                            transform: 'scale(1.1)',
                                            color: 'primary.dark',
                                        }
                                    },
                                    '&:active': {
                                        backgroundColor: 'rgba(25, 118, 210, 0.25)',
                                        transform: 'scale(0.98)',
                                    }
                                }}
                            >
                                <AccessTimeIcon 
                                    className="play-icon"
                                    sx={{ 
                                        fontSize: '1rem',
                                        transition: 'transform 0.2s ease',
                                        color: 'primary.main',
                                    }} 
                                />
                                {timestamp}
                            </Box>
                        </Tooltip>
                    );

                    lastIndex = endIndex;
                }

                // Process remaining text
                if (lastIndex < line.length) {
                    parts.push(...processText(line.slice(lastIndex)));
                }

                return (
                    <React.Fragment key={i}>
                        {parts}
                        <br />
                    </React.Fragment>
                );
            });
        };

        return (
            <Box 
                key={index} 
                sx={{ 
                    mb: 2, 
                    p: 2, 
                    backgroundColor: msg.role === 'user' ? '#E3F2FD' : '#F5F5F5',
                    borderRadius: 2,
                    maxWidth: '80%',
                    alignSelf: msg.role === 'user' ? 'flex-end' : 'flex-start',
                    boxShadow: '0px 2px 4px rgba(0,0,0,0.1)'
                }}
            >
                <Typography 
                    variant="body1" 
                    sx={{ color: msg.role === 'user' ? '#1976D2' : '#333333' }}
                >
                    <strong>{msg.role === 'user' ? 'You: ' : 'Heartee.AI: '}</strong>
                    {formatContent(msg.content)}
                </Typography>
            </Box>
        );
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    // Update the isTextTruncated function to be more reliable
    const isTextTruncated = (text) => {
        // Create a temporary span element to measure text width
        const span = document.createElement('span');
        span.style.visibility = 'hidden';
        span.style.position = 'absolute';
        span.style.fontSize = '0.9rem'; // Match the font size used in the component
        span.style.whiteSpace = 'nowrap';
        span.textContent = text;
        document.body.appendChild(span);
        
        // Get the width and compare with typical container widths
        const textWidth = span.offsetWidth;
        document.body.removeChild(span);
        
        // Return true if text width exceeds typical container widths
        return textWidth > 200; // Adjust this value based on your needs
    };

    const returnToCurrentChat = async () => {
        if (newestThreadId && newestThreadId !== lmsThreadId) {
            setLMSThreadId(newestThreadId);
            setIsLoadingMessages(true);
            try {
                await fetchMessages(newestThreadId);
            } catch (error) {
                console.error('Error loading current chat:', error);
            } finally {
                setIsLoadingMessages(false);
            }
        }
    };

    const renderChatHistorySection = () => (
        <Box sx={{ maxHeight: '30vh', overflow: 'auto' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6">Chat History</Typography>
                {lmsThreadId !== newestThreadId && (
                    <Button 
                        size="small" 
                        variant="outlined" 
                        onClick={returnToCurrentChat}
                        startIcon={<AccessTimeIcon />}
                    >
                        Return to Current Chat
                    </Button>
                )}
            </Box>
            <ChatHistoryNavbar chatHistory={chatHistory} onThreadSelect={handleThreadSelect} />
        </Box>
    );

    return (
        <Box sx={{ 
            display: 'flex',
            backgroundColor: (theme) => theme.palette.grey[100],
            minHeight: '100vh',
            width: '100%',
            top: 0,
            left: 0,
            right: 0,
            overflow: 'hidden'
        }}>
            <Drawer
                variant="permanent"
                anchor="left"
                open={drawerOpen}
                sx={{
                    width: drawerOpen ? drawerWidth : 56,
                    flexShrink: 0,
                    top: 0,
                    height: '100%',
                    '& .MuiDrawer-paper': {
                        position: 'relative',
                        width: drawerOpen ? drawerWidth : 56,
                        boxSizing: 'border-box',
                        transition: 'width 0.2s',
                        overflowX: 'hidden'
                    },
                }}
            >
                <Toolbar />
                <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: drawerOpen ? 'space-between' : 'center',
                    p: 1
                }}>
                    {drawerOpen && (
                        <Typography 
                            variant="h6" 
                            sx={{ 
                                color: '#1976D2', // Material-UI primary blue color
                                fontWeight: 'medium',
                                pl: 2
                            }}
                        >
                            Select Course
                        </Typography>
                    )}
                    <IconButton onClick={handleDrawerToggle}>
                        {drawerOpen ? <ChevronLeftIcon /> : <MenuIcon />}
                    </IconButton>
                </Box>
                <Divider />
                <List>
                    {courses.map((course, index) => (
                        <React.Fragment key={course.id}>
                            <ListItem 
                                button 
                                onClick={() => handleCourseSelect(course.course_name)}
                                sx={{ 
                                    justifyContent: drawerOpen ? 'flex-start' : 'center',
                                    px: drawerOpen ? 2 : 1,
                                    bgcolor: selectedCourseId === course.course_name ? 'action.selected' : 'inherit', // Highlight selected course
                                    '&:hover': {
                                        bgcolor: selectedCourseId === course.course_name 
                                            ? 'action.selected' 
                                            : 'action.hover'
                                    },
                                    // Optional: Add a left border to make selection more visible
                                    borderLeft: selectedCourseId === course.course_name 
                                        ? `4px solid ${theme.palette.primary.main}` 
                                        : '4px solid transparent'
                                }}
                            >
                                <ListItemText 
                                    primary={drawerOpen ? course.course_name : `#${index + 1}`}
                                    sx={{ 
                                        opacity: drawerOpen ? 1 : 0,
                                        display: drawerOpen ? 'block' : 'none',
                                        // Optional: Make text bold when selected
                                        '& .MuiTypography-root': {
                                            fontWeight: selectedCourseId === course.course_name ? 'bold' : 'normal',
                                            color: selectedCourseId === course.course_name 
                                                ? 'primary.main' 
                                                : 'text.primary'
                                        }
                                    }}
                                />
                                {!drawerOpen && (
                                    <Typography 
                                        variant="body2"
                                        sx={{
                                            fontWeight: selectedCourseId === course.course_name ? 'bold' : 'normal',
                                            color: selectedCourseId === course.course_name 
                                                ? 'primary.main' 
                                                : 'text.primary'
                                        }}
                                    >
                                        {index + 1}
                                    </Typography>
                                )}
                            </ListItem>
                            {index < courses.length - 1 && <Divider />}
                        </React.Fragment>
                    ))}
                </List>
            </Drawer>

            <Box 
                sx={{
                    flexGrow: 1,
                    backgroundColor: (theme) => theme.palette.grey[100],
                    height: '100vh',
                    overflow: 'auto',
                    position: 'relative'
                }}
            >
                <Toolbar />
                <Container maxWidth="xl" sx={{ 
                    mt: 4, 
                    mb: 4,
                    height: 'calc(100vh - 112px)',
                    backgroundColor: (theme) => theme.palette.grey[100],
                }}>
                    <Box sx={{ 
                        display: 'flex', 
                        flexDirection: { xs: 'column', md: 'row' }, 
                        gap: 2,
                        justifyContent: (!selectedCourseId || videos.length === 0) ? 'center' : 'flex-start',
                        backgroundColor: (theme) => theme.palette.grey[100],
                    }}>
                        {selectedCourseId && videos.length > 0 && renderVideoSection()}
        
                        <Box sx={{ 
                            width: (!selectedCourseId || videos.length === 0) ? '100%' : { xs: '100%', md: '35%' }, // Changed from 40% to 35%
                            maxWidth: (!selectedCourseId || videos.length === 0) ? '600px' : 'none', // Changed from 800px to 600px
                            display: 'flex', 
                            flexDirection: 'column',
                            backgroundColor: (theme) => theme.palette.grey[100],
                        }}>
                            <Paper sx={{ mb: 2, p: 2, backgroundColor: (theme) => theme.palette.grey[50] }}>
                                <Button 
                                    fullWidth
                                    onClick={() => setIsChatHistoryVisible(!isChatHistoryVisible)}
                                    sx={{ mb: isChatHistoryVisible ? 2 : 0 }}
                                >
                                    {isChatHistoryVisible ? 'Hide Chat History' : 'Show Chat History'}
                                </Button>
                                
                                {isChatHistoryVisible && renderChatHistorySection()}
                            </Paper>
                            
                            <Paper 
                                sx={{ 
                                    flexGrow: 1, 
                                    p: 2, 
                                    display: 'flex', 
                                    flexDirection: 'column', 
                                    height: '70vh',
                                    backgroundColor: '#f5f5f5',
                                }}
                            >
                                <Box sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.08)', mb: 2 }} >
                                <Typography 
                                    variant="h6" 
                                    sx={{ 
                                        mb: 2,
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1.2,
                                        fontSize: '1.5rem',
                                    }}
                                >
                                    <Box
                                        component="span"
                                        sx={{
                                            color: '#555',
                                            fontWeight: 500,
                                            borderBottom: '2px solid #2196F3',
                                        }}
                                    >
                                        Ask
                                    </Box>
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            display: 'inline-flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Box
                                            component="span"
                                            sx={{
                                                backgroundColor: '#2196F3',
                                                color: 'white',
                                                padding: '6px 16px',
                                                borderRadius: '4px',
                                                fontWeight: 500,
                                                letterSpacing: '0.3px',
                                                position: 'relative',
                                                overflow: 'hidden',
                                                '&::before': {
                                                    content: '""',
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: -10,
                                                    width: '20px',
                                                    height: '100%',
                                                    background: 'rgba(255, 255, 255, 0.2)',
                                                    transform: 'skewX(-20deg)',
                                                }
                                            }}
                                        >
                                            Heartee
                                            <Box
                                                component="span"
                                                sx={{
                                                    opacity: 0.9,
                                                    marginLeft: '1px',
                                                    display: 'inline-flex',
                                                    alignItems: 'center',
                                                    gap: 0.5,
                                                }}
                                            >
                                                .AI
                                                <Box
                                                    sx={{
                                                        width: '4px',
                                                        height: '4px',
                                                        backgroundColor: 'currentColor',
                                                        borderRadius: '50%',
                                                        animation: 'pulse 2s infinite',
                                                        '@keyframes pulse': {
                                                            '0%': { opacity: 1 },
                                                            '50%': { opacity: 0.4 },
                                                            '100%': { opacity: 1 },
                                                        }
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Typography>
                                </Box>
                                <Box sx={{ 
                                    flexGrow: 1, 
                                    overflow: 'auto', 
                                    mb: 2, 
                                    display: 'flex', 
                                    flexDirection: 'column',
                                    maxHeight: 'calc(100% - 120px)'
                                }}>
                                    {isLoadingMessages ? (
                                        <CircularProgress />
                                    ) : (
                                        messages.map((msg, index) => renderMessage(msg, index))
                                    )}
                                    <div ref={messagesEndRef} />
                                </Box>
                                
                                {isLoading && <LinearProgress sx={{ mb: 2 }} />}
                                <Box component="form" sx={{ display: 'flex' }} onSubmit={(e) => { e.preventDefault(); sendMessage(); }}>
                                    <TextField 
                                        fullWidth 
                                        variant="outlined" 
                                        placeholder="Type your question..." 
                                        value={inputMessage}
                                        onChange={(e) => setInputMessage(e.target.value)}
                                        sx={{ mr: 1 }} 
                                    />
                                    <Button variant="contained" type="submit">Send</Button>
                                </Box>
                            </Paper>
                        </Box>
                    </Box>
                </Container>
            </Box>

            <Snackbar 
                open={openSnackbar} 
                autoHideDuration={6000} 
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert 
                    onClose={handleSnackbarClose} 
                    severity={snackbarSeverity} 
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default LMSHome;
