import React, { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Select from'@mui/material/Select';
import MenuItem from'@mui/material/MenuItem';
import InputLabel from'@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';  
import Typography from '@mui/material/Typography';
import { BASE_URL } from '../../Constants';
import { getToken, getUserId } from '../../auth/auth.js';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const mdTheme = createTheme();

const ContentWrapper = styled('div')({
    flex: '1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  });

function AddUser() {

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState('student');

  const navigate = useNavigate();
  const token = getToken(); 
  const userId = getUserId(); 


  // Email validation utility function
  const isValidEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!isValidEmail(email)) {
        toast.error('Invalid Email', {
          autoClose: 3000,  
          position: toast.POSITION.TOP_CENTER,
        });
        return;
    }  

    const formData = {
      firstName,
      lastName,
      email,
      role: role.toLowerCase(),
      userId: userId
    };

    fetch(`${BASE_URL}/api/addadminorstudent`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization':   `Bearer ${token}`
      },
      body: JSON.stringify(formData),
    })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Failed to add user');
      }
    })
    .then((data) => {
      console.log('Success:', data);
      // Reset all form fields
      setFirstName("");
      setLastName("");
      setEmail("");
      setRole("student");
      toast.success('Success!', {
          autoClose: 2000,
          position: toast.POSITION.TOP_CENTER,
      });
    })
    .catch((error) => {
      console.error('Error:', error);
    });

    //add email to allow list?
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            minHeight: '100vh',
            overflow: 'auto',
            pt: '2em', 

          }}
          
      >
        <CssBaseline />

          <Toolbar />
          <ContentWrapper>
            
            <Box sx={{
              display: 'flex',
              justifyContent: 'center', 
            }}>
              <Typography color="#1976D2" variant="h6" component="h2" gutterBottom>
                Add New Admin/Student Details
              </Typography>
            </Box>

            <Paper 
              sx={{ 
                padding: '1em',
                maxWidth: '500px',
              }}>

              {/* First Name */}
              <Box margin="1em">
                  <TextField
                    label="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    fullWidth
                    margin="normal"
                  />
              </Box>

              {/* Last Name */}
              <Box margin="1em">
                <TextField
                  label="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  fullWidth
                  margin="normal"
                />
              </Box>

              {/* Email */}
              <Box margin="1em">
                <TextField
                  label="Email"
                  value={email}
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth
                  margin="normal"
                />
              </Box>

              {/* Add Role dropdown */}
              <FormControl fullWidth sx={{ marginTop: '1em' }}>
                <InputLabel id="role-label">Role</InputLabel>
                <Select
                  labelId="role-label"
                  id="role"
                  value={role}
                  label="Role"
                  onChange={(e) => setRole(e.target.value)}
                >
                  <MenuItem value="admin">Admin</MenuItem>
                  <MenuItem value="student">Student</MenuItem>
                </Select>
              </FormControl>
            
            </Paper>

            {/* Submit button */}
            <Button sx={{ marginTop: '20px' }} variant="contained" color="primary" onClick={handleSubmit}>
                Create
            </Button>

          </ContentWrapper>

      </Box>
    </ThemeProvider>
  );
}

export default AddUser;