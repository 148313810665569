import React, { useEffect, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { BASE_URL } from '../../Constants';
import TextField from '@mui/material/TextField';
import SaveIcon from '@mui/icons-material/Save';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { getToken, getUserId } from '../../auth/auth.js';

function UserProfile() {
  const [userData, setUserData] = useState(null);
  const [editedData, setEditedData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [avatarURL, setAvatarURL] = useState(null);

  const token = getToken();
  const userId = getUserId();

  const fetchUserData = () => {
    fetch(`${BASE_URL}/api/readuser/${userId}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then(response => {
      if (response.ok) { 
        return response.json();
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    })
    .then(data => {
      const modifiedData = {
        firstName: data.first_name,
        lastName: data.last_name,
        email: data.email,
        avatar: 'https://via.placeholder.com/150'
      };
      setUserData(modifiedData);
      setEditedData(modifiedData);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }

  useEffect(() => {
    fetchUserData(userId);
  }, [userId]);

  if (!userData) {
    return <div>Loading...</div>;
  }

  const handleEditClick = () => {
    setIsEditing(true);
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name !== 'email') {
      setEditedData({ ...editedData, [name]: value });
    }
  }

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    
    // Create a URL for the selected file
    const imageURL = URL.createObjectURL(event.target.files[0]);
    setAvatarURL(imageURL);
  };

  const handleCancelClick = () => {
    setEditedData(userData);  // Revert the edited data
    setIsEditing(false);  // Exit edit mode
  };

  const handleSaveClick = () => {
    const requestOptions = {
      method: 'PUT',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ 
        firstName: editedData.firstName, 
        lastName: editedData.lastName,
      })
    };
  
    fetch(`${BASE_URL}/api/updateuser/${userId}`, requestOptions)
      .then(response => response.json())
      .then(data => {
        setIsEditing(false);
        fetchUserData();
        console.log(data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };
  
  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        minHeight: '100vh',
        overflow: 'auto',
      }}
    
    >
      <CssBaseline />
      <Toolbar />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Card style={{ marginTop: '2em', position: 'relative' }}>

          <div style={{ position: 'absolute', right: '10px', top: '10px' }}>
            {isEditing
              ? (
                  <>

                  <IconButton
                    onClick={handleSaveClick}
                  >
                    <SaveIcon />
                  </IconButton>

                  <IconButton
                    onClick={handleCancelClick}
                  >
                    <CancelOutlinedIcon />
                  </IconButton>

                  </>
                )
                : (
                  <IconButton
                    onClick={handleEditClick}
                  >
                    <EditIcon />
                  </IconButton>
                )
            }
          </div>

          <CardContent>

            <Box display="flex" alignItems="center">

              <div style={{ position: 'relative' }}>
                <Avatar
                  src={avatarURL || userData.avatar}
                  alt={`${userData.firstName} ${userData.lastName}`}
                  sx={{ width: 100, height: 100, marginRight: '2em' }}
                />

                {isEditing && (
                  <div style={{ position: 'absolute', top: '68px', right: '32px', cursor: 'pointer' }}>
                    <input 
                      type="file" 
                      id="fileInput" 
                      style={{ display: 'none' }} 
                      onChange={handleFileChange}
                    />
                    <label htmlFor="fileInput">
                      <span style={{
                        display: 'inline-flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'white',
                        borderRadius: '50%', // This makes it circular
                        width: '30px', // Adjust based on your needs
                        height: '30px', // Adjust based on your needs
                        border: '1px solid gray',
                      }}>
                        <EditIcon style={{ color: 'grey' }} />
                      </span>
                    </label>
                  </div>
                )}
              </div>
                             
              <Box>

                <Box display="flex" flexWrap="wrap">
                  {isEditing
                    ? <>
                        <TextField name="firstName" label="First Name" value={editedData.firstName} onChange={handleInputChange} />
                        <TextField name="lastName" label="Last Name" value={editedData.lastName} onChange={handleInputChange} />
                      </>
                    : <Typography variant="h5" component="h2">{`${userData.firstName} ${userData.lastName}`}</Typography>
                  }
                </Box>

                <Typography color="textSecondary" gutterBottom>Email: {userData.email}</Typography>

              </Box>
              
            </Box>
            
          </CardContent>

        </Card>
      </Container>
    </Box>
  );
  
}

export default UserProfile;
