import React, { useEffect, useState } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import ViewIcon from '@mui/icons-material/Visibility';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import ScaleIcon from '@mui/icons-material/Scale';
import GroupIcon from '@mui/icons-material/Group';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography'; 
import { Link, useLocation, useNavigate } from 'react-router-dom';
import LocalFloristIcon from '@mui/icons-material/LocalFlorist';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import Rotate90DegreesCwIcon from '@mui/icons-material/Rotate90DegreesCw';
import FolderDeleteIcon from '@mui/icons-material/FolderDelete';
import CategoryIcon from '@mui/icons-material/Category';
import PersonIcon from '@mui/icons-material/Person';
import SchoolIcon from '@mui/icons-material/School';
import ChecklistIcon from '@mui/icons-material/Checklist';
import SettingsIcon from '@mui/icons-material/Settings';
import { getUserId, getUserRole } from '../../auth/auth.js';
import MenuBookIcon from '@mui/icons-material/MenuBook';  // or any other icon you prefer



export const LmsListItems = ({ navBarOpen }) => {
  const [userId, setUserId] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const allowedUserIds = [1, 2, 72, 88];
  const location = useLocation();

  useEffect(() => {
    const fetchUserId = async () => {
      const currentUserId = await getUserId();
      console.log('Current User ID:', currentUserId);
      setUserId(currentUserId);
    };

    fetchUserId();
  }, []);

  useEffect(() => {
    const userRole = getUserRole();
    setIsAdmin(userRole === 'admin');
  }, []);

  const isUserAllowed = allowedUserIds.some(id => 
    String(id) === String(userId)
  );

  return (
    <React.Fragment>
      <ListSubheader 
        component="div" 
        inset={navBarOpen}
        sx={{ 
          pt: 1,
          pl: navBarOpen ? undefined : 2
        }}
      >
        {navBarOpen ? (
          "LMS"
        ) : (
          <ListItemIcon sx={{ 
            minWidth: 'auto',
            
          }}>
            <MenuBookIcon /> 
          </ListItemIcon>
        )}
      </ListSubheader>

      <ListItemButton 
        component={Link} 
        to="/"
        selected={location.pathname === '/'}
        sx={{
          '&.Mui-selected': {
            backgroundColor: 'rgba(25, 118, 210, 0.08)',
            '&:hover': {
              backgroundColor: 'rgba(25, 118, 210, 0.12)',
            },
          },
        }}
      >
        <ListItemIcon>
          <SchoolIcon />
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItemButton>

      {isAdmin && (
        <ListItemButton component={Link} to="/lmsactivitylog">
          <ListItemIcon>
            <ChecklistIcon />
          </ListItemIcon>
          <ListItemText primary="Activity Log" />
        </ListItemButton>
      )}

      {isAdmin && (
        <ListItemButton component={Link} to="/lmsaddcourse">
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText primary="New Course" />
        </ListItemButton>
      )}

      {isAdmin && (
        <ListItemButton component={Link} to="/lmsaddvideos">
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText primary="New Videos" />
        </ListItemButton>
      )}

    </React.Fragment>
  );
};

export const manageListItems = (
  <React.Fragment>

    <ListSubheader component="div" inset>
      Manage
    </ListSubheader>

    <ListItemButton component={Link} to="/manageAdmins">
      <ListItemIcon>
        <PersonIcon />
      </ListItemIcon>
      <ListItemText primary="Admins" />
    </ListItemButton>

    <ListItemButton component={Link} to="/manageStudents">
      <ListItemIcon>
        <GroupIcon />
      </ListItemIcon>
      <ListItemText primary="Students" />
    </ListItemButton>
    
  </React.Fragment>
);

export const ManageListItems = ({ handleClick, openManage, navBarOpen }) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  
  useEffect(() => {
    const userRole = getUserRole();
    setIsAdmin(userRole === 'admin');
  }, [location]);

  useEffect(() => {
    if (!isAdmin && (location.pathname === '/manageadmins' || location.pathname === '/managestudents')) {
      navigate('/');
    }
  }, [isAdmin, location, navigate]);

  if (!isAdmin) return null;

  return (
    <React.Fragment>
      <ListItemButton onClick={() => handleClick('Manage')}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Manage" />
        {openManage ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openManage} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton component={Link} to="/manageadmins" sx={{ paddingLeft: navBarOpen ? 9 : 2.7, color: 'gray' }}>
            <ListItemIcon sx={{ minWidth: 32 }}>
              <PersonIcon sx={{ fontSize: navBarOpen ? '24px' : 'small' }}/>
            </ListItemIcon>
            {navBarOpen && <ListItemText primary={<Typography variant="body2">Admins</Typography>} />}
          </ListItemButton>
          <ListItemButton component={Link} to="/managestudents" sx={{ paddingLeft: navBarOpen ? 9 : 2.7, color: 'gray' }}>
            <ListItemIcon sx={{ minWidth: 32 }}>
              <GroupIcon sx={{ fontSize: navBarOpen ? '24px' : 'small' }}/>
            </ListItemIcon>
            {navBarOpen && <ListItemText primary={<Typography variant="body2">Students</Typography>} />}
          </ListItemButton>
        </List>
      </Collapse>
    </React.Fragment>
  );
};
